import React, { Component } from 'react'

class AddTag extends Component {
  state = { editing: false, input: '' }

  handleChange = e => this.setState({ input: e.target.value })

  toggleState = () => this.setState({ editing: !this.state.editing })

  handleKey = e => {
    if (e.key === 'Enter') {
      this.setState({ editing: false, input: '' })
      this.props.onAdd(this.state.input)
    }
  }

  render() {
    const { editing, input } = this.state
    return (
      <button
        className="button-light px-2 py-1 m-1 text-xs hover:bg-blue-lightest hover:text-blue-dark"
        onClick={!editing ? this.toggleState : _ => _}
        onKeyUp={this.handleKey}
      >
        <i className="fas fa-plus" />
        {editing ? (
          <input
            type="text"
            value={input}
            onChange={this.handleChange}
            className="bg-transparent focus:outline-none ml-1 text-blue-dark font-bold p-0"
            autoFocus
            autoCapitalize={false}
            autoCorrect={false}
          />
        ) : (
          <span className="ml-1">Add Field</span>
        )}
      </button>
    )
  }
}

export default AddTag
