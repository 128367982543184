import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import EventDetail from '../components/EventDetail/EventDetail'
import GuestImport from '../components/GuestImport/GuestImport'
import EventSettings from '../components/EventSettings/EventSettings'

class EventPage extends Component {
  componentDidMount() {
    this.getEvent()
  }

  getEvent = () => {
    const id = this.props.match.params.id

    this.props.watchEvent(id)
    this.props.watchGuests(id)
  }

  render() {
    const { loading, event, guests } = this.props

    if (loading)
      return <div className="container mx-auto text-left"> Loading... </div>

    if (!event) return <div>This event does not exist</div>

    const flatEvent = { id: this.props.match.params.id, ...event }

    return (
      <div>
        <Switch>
          <Route
            path={`${this.props.match.path}/`}
            exact
            component={() => <EventDetail event={flatEvent} guests={guests} />}
          />
          <Route
            path={`${this.props.match.path}/import`}
            component={() => <GuestImport event={flatEvent} />}
          />
          <Route
            path={`${this.props.match.path}/settings`}
            component={() => <EventSettings event={flatEvent} />}
          />
        </Switch>
      </div>
    )
  }
}

const mapState = ({ loading, events, guests }, props) => ({
  loading: loading.effects.events.getEvent,
  loadingGuests: guests.loading,
  event: events[props.match.params.id],
  guests: guests[props.match.params.id]
})
const mapDispatch = ({
  events: { watchEvent },
  guests: { watchGuests, toggleGuest }
}) => ({
  watchEvent,
  watchGuests,
  toggleGuest
})

export default connect(
  mapState,
  mapDispatch
)(EventPage)
