import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import { Redirect } from 'react-router-dom'
import AddTag from '../AddTag/AddTag'
import { db } from '../../firebase'

const FieldTag = ({ name, required = false, onDelete }) => (
  <div className="px-2 py-1 rounded-sm m-1 text-xs capitalize inline-block bg-grey-light text-grey-darker font-bold inline-flex items-center">
    {name}

    {!required && (
      <button
        className="pl-2 text-xs text-grey focus:outline-none hover:text-grey-dark"
        onClick={onDelete}
      >
        <i className="fas fa-times" />
      </button>
    )}
  </div>
)

class GuestImport extends Component {
  componentDidMount() {
    this.setFields()
  }

  componentDidUpdate() {
    this.setFields()
  }

  setFields = () => this.props.update({ fields: this.props.event.fields })

  capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

  options = field => {
    const { headers, map } = this.props
    return (
      <div key={field} className="flex items-center justify-between mb-2">
        <p className="text-grey-darker font-bold">
          {this.capitalize(field)} <span className="font-normal">maps to</span>
        </p>
        <div className="flex-1 h-px bg-grey-lighter mx-4 mt-1" />
        <select
          name={field}
          value={map[field]}
          onChange={e => this.props.updateMap({ field, value: e.target.value })}
          className="inline"
        >
          <option key={0} value={null}>
            Ignore
          </option>
          {headers.map(h => (
            <option key={h} value={h}>
              {h}
            </option>
          ))}
        </select>
      </div>
    )
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const file = acceptedFiles[0]
    if (!file) return
    this.props.loadFile(file)
  }

  addField = name => {
    const { event } = this.props

    const fields = [...event.fields]
    const newField = { name, required: false }
    if (fields.indexOf(newField) < 0) fields.push(newField)

    db.collection('events')
      .doc(event.id)
      .update({ fields })
  }

  deleteField = name => {
    const { event } = this.props
    const fields = event.fields.filter(
      field => field.required || field.name !== name
    )

    db.collection('events')
      .doc(event.id)
      .update({ fields })
  }

  Uploader = () => (
    <Dropzone
      className="w-full h-64 border-grey-light border-2 cursor-pointer hover:bg-grey-lightest border-dashed rounded flex items-center justify-center text-grey font-bold"
      onDrop={this.onDrop}
      accept=".csv, text/csv"
      multiple={false}
    >
      Tap, click, or drag to upload a csv
    </Dropzone>
  )

  render() {
    const { data, parsed, event, limit, imported } = this.props

    if (imported) return <Redirect to={`/events/${event.id}`} />

    const guests = data.slice(0, limit)

    return (
      <div className="container mx-auto px-6 sm:px  -0">
        <div className="max-w-md mx-auto text-left">
          {parsed && (
            <button
              className="text-grey-dark focus:outline-none mb-2"
              onClick={this.props.back}
            >
              <i className="fas fa-chevron-left text-grey" /> Back
            </button>
          )}
          <h1 className="">Import Guest List</h1>

          <p className="leading-normal mb-4 mt-2">
            If you already have your data in a spreadsheet (Excel, Google Docs),
            you can export it to a CSV file, and import it directly to Chekhov
            in no time.
          </p>

          {!parsed && <this.Uploader />}
          {parsed && (
            <div>
              <div>
                <div>
                  <p className="text-grey-dark text-sm font-bold uppercase tracking-wide mb-2">
                    Event Fields
                  </p>
                  <div className="-m-1">
                    {event.fields.map(({ name, required }) => (
                      <FieldTag
                        key={name}
                        name={name}
                        required={required}
                        onDelete={() => this.deleteField(name)}
                      />
                    ))}
                    <AddTag onAdd={this.addField} />
                  </div>
                  <p className="text-grey-dark text-sm font-bold uppercase tracking-wide mb-2 mt-4">
                    Map Fields
                  </p>
                  <div>
                    {event.fields.map(({ name }) => this.options(name))}
                  </div>
                </div>
                <p className="text-grey-dark text-sm font-bold uppercase tracking-wide mb-2 mt-4">
                  Preview
                </p>
                <table className="w-full">
                  <tbody>
                    <tr>
                      {guests &&
                        event.fields.map(({ name }) => (
                          <th
                            key={name}
                            className="text-xs font-bold text-grey uppercase tracking-wide"
                          >
                            {name}
                          </th>
                        ))}
                    </tr>
                    {guests.map((guest, i) => (
                      <tr key={i} className="py-2">
                        {event.fields.map(({ name }) => (
                          <td key={name}>
                            <p>
                              {guest[name] || (
                                <span className="text-grey">--</span>
                              )}
                            </p>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="w-full my-4">
                <button
                  className="button-light text-lg flex items-center mx-auto"
                  onClick={() => this.props.saveGuests(event.id)}
                >
                  <i className="fas fa-cloud-upload-alt text-blue mr-2 text-sm" />
                  Import Guests
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapState = ({ parse }) => ({
  file: parse.file,
  data: parse.data,
  parsed: !!parse.file,
  headers: parse.headers,
  map: parse.map,
  limit: parse.limit,
  imported: parse.imported
})

const mapDispatch = dispatch => ({
  loadFile: dispatch.parse.loadFile,
  updateMap: dispatch.parse.updateMap,
  back: dispatch.parse.RESET,
  update: dispatch.parse.UPDATE,
  saveGuests: dispatch.guests.saveGuests
})

export default connect(
  mapState,
  mapDispatch
)(GuestImport)
