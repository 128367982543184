import React, { Component } from 'react'
import { Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'

import './App.css'
import Login from '../../pages/Login'
import PrivateRoute from '../PrivateRoute'
import PublicOnlyRoute from '../PublicOnlyRoute'
import PageHome from '../../pages/Home'
import Nav from '../../components/Nav/Nav'
import CreateForm from '../../components/CreateForm/CreateForm'
import EventPage from '../../pages/Event'
import Footer from '../../components/Footer/Footer'

class App extends Component {
  componentDidMount() {
    this.props.watchAuth()
  }

  render() {
    return (
      <div className="App font-sans text-black flex flex-col min-h-full">
        <main className="flex-1">
          <Nav />
          <Switch>
            <PublicOnlyRoute path="/login" component={Login} />
            <PrivateRoute path="/" exact component={PageHome} />
            <PrivateRoute path="/create" exact component={CreateForm} />
            <PrivateRoute path="/events/:id" component={EventPage} />
          </Switch>
        </main>
        <Footer />
      </div>
    )
  }
}

const mapState = state => ({ auth: !!state.auth.uid })
const mapDispatch = ({ auth }) => ({
  watchAuth: auth.watchAuthState,
  logout: auth.requestLogout
})

export default compose(
  withRouter,
  connect(
    mapState,
    mapDispatch
  )
)(App)
