import Papa from 'papaparse'

const initialState = {
  file: null,
  data: [],
  headers: [],
  fields: [],
  limit: 10,
  map: {
    name: 'Name'
  },
  imported: false
}

const parsePromise = (file, options) =>
  new Promise((resolve, reject) =>
    Papa.parse(file, { ...options, complete: resolve })
  )

const readFileContent = file => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onload = event => resolve(event.target.result)
    reader.onerror = error => reject(error)
    reader.readAsText(file)
  })
}

export default {
  state: initialState,
  reducers: {
    SET_FILE: (state, file) => ({ ...state, file }),
    SET_FIELDS: (state, fields) => ({ ...state, fields }),
    UPDATE: (state, payload) => ({ ...state, ...payload }),
    RESET: () => initialState
  },
  effects: dispatch => ({
    loadFile: async file => {
      const text = await readFileContent(file)
      dispatch.parse.SET_FILE(text)
      dispatch.parse.parse()
    },

    updateMap: ({ field, value }, rootState) => {
      if (value === 'Ignore ') value = null
      dispatch.parse.UPDATE({ map: { ...rootState.parse.map, [field]: value } })
      dispatch.parse.parse()
    },

    parse: async (_, rootState) => {
      const { map, file, fields, limit } = rootState.parse
      const results = await parsePromise(file, { header: true })
      // Set our headers object
      const headers = Object.keys(results.data[0])
      dispatch.parse.UPDATE({ headers })

      // Trim the data to what we want
      const head = results.data

      console.log('map: ', map)
      // Map through the first five rows so we can merge them into a guests array
      const data = head.map(parsedRow =>
        // Now for each parsed row, we map it into hte fields we want
        fields.reduce(
          (cur, field) => ({
            ...cur,
            [field.name]: parsedRow[map[field.name]]
          }),
          {}
        )
      )

      dispatch.parse.UPDATE({ data })
    }
  })
}
