import { db } from '../../firebase'

export default {
  state: {}, // initial state
  reducers: {
    // handle state changes with pure functions
    SET_EVENT(state, { id, data }) {
      return {
        ...state,
        [id]: data
      }
    },

    SET_EVENT_GUESTS: (state, { id, guests }) => ({
      ...state,
      id: { ...state[id], guests }
    }),

    SET_EVENTS(state, events) {
      return events
    }
  },

  effects: dispatch => ({
    async getUserEvents(payload, rootState) {
      db.collection('events')
        .where('uid', '==', rootState.auth.uid)
        .orderBy('created', 'desc')
        .onSnapshot(snapshot => {
          const events = []
          snapshot.forEach(doc => (events[doc.id] = doc.data()))

          dispatch.events.SET_EVENTS(events)
        })
    },

    saveEvent(event, rootState) {
      const payload = { uid: rootState.auth.uid, created: new Date(), ...event }

      return db.collection('events').add(payload)
    },

    watchEvent(id) {
      db.collection('events')
        .doc(id)
        .onSnapshot(event => {
          if (event.exists) {
            dispatch.events.SET_EVENT({ id, data: event.data() })
          }
          return null
        })
    }
  })
}
