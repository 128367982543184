import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import GuestList from '../GuestList/GuestList'
import { Link } from 'react-router-dom'

class EventDetail extends Component {
  state = {
    query: ''
  }

  // componentDidMount() {
  //   this.getEvent()
  // }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      // select first
    }
  }
  handleSeach = e => this.setState({ query: e.target.value })

  // getEvent = () => {
  //   const id = this.props.match.params.id

  //   this.props.watchEvent(id)
  //   this.props.watchGuests(id)
  // }

  render() {
    const { event, guests, loadingGuests } = this.props
    const { query } = this.state

    // if (loading)
    //   return <div className="container mx-auto text-left"> Loading... </div>

    if (!event) return <div>This event does not exist</div>

    return (
      <div className="max-w-md mx-auto text-left px-6 sm:px-0">
        <header>
          <div className="flex justify-between items-center">
            <h1>{event.name}</h1>
            <div>
              <Link
                to={`/events/${event.id}/settings`}
                className="text-xs font-semibold text-grey-darker no-underline mr-2"
              >
                Settings
              </Link>
              <Link
                to={`/events/${event.id}/import`}
                className="button-light text-xs font-semibold no-underline"
              >
                Add Guest
              </Link>
            </div>
          </div>
          <div className="mt-1 text-grey-darker">
            <span className="mr-4 my-1 inline-flex items-center ">
              <i className="fas text-xs text-grey fa-calendar mr-2" />
              {moment(event.date).format('dddd, MMMM Do YYYY')}
            </span>
            <span className="mr-4 my-1 inline-flex items-center ">
              <i className="fas text-xs text-grey fa-clock mr-2" />
              {moment(event.time).format('h:mm A')}
            </span>
            <span className="mr-4 my-1 inline-flex items-center ">
              <i className="fas text-xs text-grey fa-map-pin mr-2" />
              {event.venue}
            </span>
          </div>
        </header>
        <main>
          <div className="my-6">
            <input
              type="text"
              placeholder="Type anywhere to search guests"
              className="search-input bg-grey-lighter px-4 py-3 text-xl block w-full focus:outline-none"
              autoFocus
              autoCorrect="off"
              autoCapitalize="none"
              value={query}
              onChange={this.handleSeach}
            />
          </div>

          <div>{!guests && loadingGuests && <div>Loading...</div>}</div>
          <div>
            {guests && (
              <GuestList
                query={query}
                guests={guests}
                eventId={this.props.event.id}
                toggleGuest={this.props.toggleGuest}
              />
            )}
          </div>
        </main>
      </div>
    )
  }
}

const mapState = state => ({})
const mapDispatch = ({ guests: { toggleGuest } }) => ({
  toggleGuest
})

export default connect(
  mapState,
  mapDispatch
)(EventDetail)
