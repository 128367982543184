import React from 'react'
import EventThumb from '../EventThumb/EventThumb'

const EventList = ({ events }) => (
  <div>
    <div className="-m-2">
      {Object.keys(events).map(id => (
        <div key={id} className="m-2 inline-block">
          <EventThumb event={events[id]} id={id} />
        </div>
      ))}
    </div>
  </div>
)

export default EventList
