import React from 'react'
import { Link } from 'react-router-dom'

const NavLink = ({ children, className, ...props }) => (
  <Link
    className={
      'no-underline text-sm font-semibold text-grey-darkest mx-2 ' + className
    }
    {...props}
  >
    {children}
  </Link>
)

const Footer = () => (
  <footer className="flex justify-between items-center py-6 container mx-auto px-6 sm:px-0 mt-16">
    <section className="-mx-2">
      <NavLink to="/">Terms</NavLink>
      <NavLink to="/">Privacy</NavLink>
      <NavLink to="/" className="text-grey-dark">
        Contact
      </NavLink>
    </section>
    <section className="-mx-2">
      <NavLink to="/">Help</NavLink>
    </section>
  </footer>
)

export default Footer
