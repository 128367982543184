import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

const FormGroup = ({ title, id, children }) => (
  <div className="text-left mb-6 flex-1">
    <label
      htmlFor={id ? id : title}
      className="block text-xs text-grey-dark tracking-wide uppercase font-bold mb-2"
    >
      {title}
    </label>
    {children}
  </div>
)

const InputGroup = ({
  title,
  id,
  className,
  value,
  onChange,
  type = 'text',
  ...props
} = {}) => (
  <FormGroup title={title} id={id} className={className}>
    <input
      type={type}
      className="focus:outline-none bg-grey-lighter px-4 py-2 block w-full text-grey-darkest"
      onChange={onChange}
      value={value}
      {...props}
    />
  </FormGroup>
)

class CreateForm extends Component {
  state = {
    name: '',
    date: '',
    venue: '',
    to: '',
    from: '',
    created: false
  }

  handleChange = field => e => this.setState({ [field]: e.target.value })

  submit = e => {
    e.preventDefault()
    const { name, date, venue, to, from } = this.state
    this.props
      .saveEvent({ name, date, venue, to, from })
      .then(event => this.setState({ created: event.id }))
  }

  render() {
    if (this.state.created)
      return <Redirect to={`/events/${this.state.created}`} />

    return (
      <div className="container mx-auto">
        <form className="max-w-sm mx-auto text-left pb-8 sm:px-0 px-6">
          <h1 className="text-base text-grey-darker font-bold pb-4 border-b border-grey-lighter mb-4">
            New Event
          </h1>

          <InputGroup
            title="Name"
            value={this.state.name}
            onChange={this.handleChange('name')}
          />

          <InputGroup
            title="Date"
            type="date"
            value={this.state.date}
            onChange={this.handleChange('date')}
          />

          <section className="flex">
            <InputGroup
              title="From"
              type="time"
              value={this.state.from}
              onChange={this.handleChange('from')}
            />
            <div className="w-2" />
            <InputGroup
              title="To"
              type="time"
              value={this.state.to}
              onChange={this.handleChange('to')}
            />
          </section>

          <InputGroup
            title="Venue"
            value={this.state.venue}
            onChange={this.handleChange('venue')}
          />

          <button
            type="submit"
            onClick={this.submit}
            className="button-light block w-full mt-4"
          >
            Create
          </button>
        </form>
      </div>
    )
  }
}

const mapDispatch = ({ auth, events }) => ({
  saveEvent: events.saveEvent
})
export default connect(
  _ => ({}),
  mapDispatch
)(CreateForm)
