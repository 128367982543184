import React from 'react'
import { Link } from 'react-router-dom'

const EventThumb = ({ event, id }) => (
  <Link
    to={`/events/${id}`}
    className="inline-block p-3 border border-grey-lighter rounded-sm hover:bg-grey-lightest no-underline text-grey-darkest"
  >
    <h1 className="text-2xl">{event.name}</h1>
  </Link>
)

export default EventThumb
