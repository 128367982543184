import React from 'react'
import classnames from 'classnames'

const checkedStyles = 'text-grey-dark'

const Guest = ({ guest, toggle }) => (
  <button
    style={{ touchAction: 'manipulation' }}
    className={classnames(
      'cursor-pointer focus:outline-none block w-full text-left hover:bg-grey-lightest rounded-sm p-2 flex justify-between items-center',
      {
        [checkedStyles]: guest.checkedIn
      }
    )}
    onClick={toggle}
  >
    {guest.name}
    <i className="fas fa-info-circle text-xs text-grey" />
  </button>
)

export default Guest
