import { init } from '@rematch/core'
import createRematchPersist from '@rematch/persist'
import createLoadingPlugin from '@rematch/loading'

import events from './models/events'
import auth from './models/auth'
import guests from './models/guests'
import parse from './models/parse'

const persistPlugin = createRematchPersist({
  whitelist: ['auth', 'events', 'guests'],
  // throttle: 5000,
  version: 1
})

const store = init({
  models: { events, auth, guests, parse },
  plugins: [persistPlugin, createLoadingPlugin()]
})

export const { dispatch } = store
export default store
