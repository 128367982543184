import React, { Component } from 'react'
import { connect } from 'react-redux'
import EventList from '../components/EventList/EventList'

class PageHome extends Component {
  componentDidMount() {
    // Get Events
    this.props.getUserEvents()
  }

  render() {
    const { events, loading } = this.props

    if (loading) return <div>Loading...</div>
    return (
      <div className="container mx-auto text-left sm:px-0 px-6">
        <EventList events={events} />
      </div>
    )
  }
}

const mapState = ({ events, loading }) => ({
  events: events,
  loading: loading.events
})

const mapDispatch = ({ events: { getUserEvents } }) => ({ getUserEvents })

export default connect(
  mapState,
  mapDispatch
)(PageHome)
