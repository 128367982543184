import React, { Component } from 'react'
import { connect } from 'react-redux'
import { auth } from '../firebase'

import Spinner from '../components/Spinner/Spinner'

const showWaitingSpinner = () => (
  <div className="flex flex-col items-center mt-16 mb-4">
    <Spinner />
    <p className="text-grey-dark font-bold mt-2">Loggin in with Google</p>
  </div>
)

class Login extends Component {
  state = {
    fromRedirect: true
  }

  componentDidMount() {
    auth()
      .getRedirectResult()
      .then(({ user }) => {
        if (!user) this.setState({ fromRedirect: false })
      })
  }

  render() {
    if (this.state.fromRedirect) return showWaitingSpinner()
    return (
      <div className="mt-16">
        <p className="mb-6 leading-normal text-lg text-grey-dark max-w-sm mx-auto">
          Sign in to access all our cool event-related features
        </p>
        <button className="button-light" onClick={() => this.props.login()}>
          Sign in with Google
        </button>
      </div>
    )
  }
}

const mapState = _ => _
const mapDispatch = dispatch => ({ login: dispatch.auth.requestLogin })
export default connect(
  mapState,
  mapDispatch
)(Login)
