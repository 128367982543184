import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

class EventSettings extends Component {
  state = {
    deleting: false
  }

  delete = () => {
    if (this.state.deleting) return
    this.setState({ deleting: true })
    this.props
      .deleteAll(this.props.event.id)
      .then(console.log)
      .catch(console.error)
      .finally(() => this.setState({ deleting: false }))
  }
  render() {
    const { event } = this.props
    const { deleting } = this.state
    return (
      <div className="container mx-auto py-6 sm:py-0">
        <div className="max-w-md mx-auto text-left">
          <Link
            to={`/events/${event.id}`}
            className="text-grey-darker text-sm no-underline block focus:outline-none mb-2"
            onClick={this.props.back}
          >
            <i className="fas fa-chevron-left text-grey" /> Back
          </Link>
          <h1>Settings</h1>

          <h2 className="mt-6 mb-3 uppercase text-lg tracking-wide text-grey-darker">
            Danger Zone
          </h2>
          <button
            className="button-light bg-red-lightest text-red-dark"
            onClick={this.delete}
            disabled={deleting}
          >
            {deleting ? 'Deleting.... (Might take a bit)' : 'Delete All Guests'}
          </button>
        </div>
      </div>
    )
  }
}

const mapState = state => ({})

const mapDispatch = dispatch => ({
  deleteAll: dispatch.guests.deleteAllGuests
})
export default connect(
  mapState,
  mapDispatch
)(EventSettings)
