import React, { Component } from 'react'
import Fuse from 'fuse.js'

import { ReactComponent as Empty } from './empty.svg'
import Guest from './Guest'
import { Link } from 'react-router-dom'

class GuestList extends Component {
  state = {
    index: null
  }

  componentDidMount() {
    this.buildIndex()
  }

  buildIndex = () => {
    const { guests } = this.props

    let all = []
    Object.keys(guests).forEach(id => all.push({ id, ...guests[id] }))
    const index = new Fuse(all, {
      keys: ['name'],
      id: 'id',
      matchAllTokens: true,
      shouldSort: true,
      threshold: 0.4
    })

    this.setState({ index })
  }

  toggle = id => this.props.toggleGuest({ id: id, eventId: this.props.eventId })

  render() {
    if (Object.keys(this.props.guests).length === 0) {
      return (
        <div className="text-center">
          <Empty className=" w-48 h-auto opacity-50 mx-auto block" />
          <p className="mt-1 text-grey-dark font-bold">
            You don't have any guests!
          </p>
          <div className="my-4">
            <Link
              to={`/events/${this.props.eventId}/import`}
              className="button-light"
            >
              Add new guest
            </Link>
            <Link
              to={`/events/${this.props.eventId}/import`}
              className="button-light bg-blue-dark text-white hover:bg-blue hover:text-white ml-3"
            >
              Import guest list
            </Link>
          </div>
        </div>
      )
    }

    let query = this.props.query.toLowerCase()
    const { index } = this.state

    if (!index) return <div>Loading...</div>

    let guests = {}
    if (!query) guests = this.props.guests
    else
      guests = index
        .search(query)
        .reduce((acc, cur) => ({ ...acc, [cur]: this.props.guests[cur] }), {})

    return (
      <div>
        {Object.keys(guests).map(id => {
          const guest = guests[id]

          return (
            <Guest
              key={id}
              guest={{ id, ...guest }}
              toggle={() => this.toggle(id)}
            />
          )
        })}
      </div>
    )
  }
}

export default GuestList
