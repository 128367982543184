import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const PublicOnlyRoute = ({ authed, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (!authed ? <Component {...props} /> : <Redirect to="/" />)}
  />
)

const mapStateToProps = ({ auth }) => ({
  authed: !!auth.uid
})

export default connect(mapStateToProps)(PublicOnlyRoute)
