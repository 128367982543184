import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'

var config = {
  apiKey: 'AIzaSyCzmryRr7gexQs7woJKrbfJhTIAB9dNxCs',
  authDomain: 'chekhov-f04d4.firebaseapp.com',
  databaseURL: 'https://chekhov-f04d4.firebaseio.com',
  projectId: 'chekhov-f04d4',
  storageBucket: 'chekhov-f04d4.appspot.com',
  messagingSenderId: '639556717649'
}

const fire = firebase.initializeApp(config)

export const db = firebase.firestore()
db.settings({ timestampsInSnapshots: true })

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
export const auth = firebase.auth

export default fire
