import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

const NavLink = ({ className = '', to, children }) => (
  <Link className={classnames('', className)} to={to}>
    {children}
  </Link>
)

const AuthedSection = ({ logout, user }) => (
  <section className="flex items-baseline">
    <button onClick={logout}>Logout</button>
    <Link to="/create" className="button-light ml-4 text-sm">
      New Event
    </Link>
  </section>
)
const NotAuthedSection = ({ login }) => (
  <section>
    <button onClick={login} className="focus:outline-none">
      <span role="img" aria-label="user" className="mr-1">
        👩
      </span>
      Login
    </button>
  </section>
)

const Nav = ({ authed, logout, login, user }) => (
  <nav className="py-6 px-6 sm:px-0 container mx-auto flex items-center justify-between">
    <section className="flex items-center">
      <NavLink to="/" className="font-bold no-underline text-black">
        Chekhov
      </NavLink>
    </section>

    {authed ? (
      <AuthedSection logout={logout} user={user} />
    ) : (
      <NotAuthedSection login={login} />
    )}
  </nav>
)

const mapState = ({ auth }) => ({
  authed: !!auth.uid,
  user: auth.profile
})

const mapDispatch = ({ auth }) => ({
  logout: auth.requestLogout,
  login: auth.requestLogin
})

export default connect(
  mapState,
  mapDispatch
)(Nav)
