import firebase, { db } from '../../firebase'

const initialState = {
  loading: {},
  parse: {
    file: null,
    data: [],
    parsed: false,
    headers: [],
    map: {
      name: 'Name'
    }
  }
}

export default {
  state: initialState,
  reducers: {
    SET_GUESTS: (state, { event, guests }) => ({
      ...state,
      [event]: { ...guests }
    }),

    SET_IMPORT: (state, update) => ({
      ...state,
      parse: { ...state.parse, ...update }
    }),

    CLEAR_IMPORT: state => ({ ...state, parse: initialState }),

    SET_LOADING: (state, loading) => ({ ...state, loading })
  },
  effects: dispatch => ({
    watchGuests: eventId => {
      dispatch.guests.SET_LOADING(true)
      db.collection('events')
        .doc(eventId)
        .collection('guests')
        .onSnapshot(snapshot => {
          let guests = []
          snapshot.forEach(guest => (guests[guest.id] = guest.data()))

          dispatch.guests.SET_LOADING(false)
          dispatch.guests.SET_GUESTS({ event: eventId, guests })
        })
    },

    toggleGuest: ({ eventId, id }, rootState) => {
      const current = rootState.guests[eventId][id]

      db.collection('events')
        .doc(eventId)
        .collection('guests')
        .doc(id)
        .update({ checkedIn: !current.checkedIn })
    },

    saveGuests: (eventId, rootState) => {
      const batch = db.batch()

      rootState.parse.data.forEach(guest => {
        const ref = db
          .collection('events')
          .doc(eventId)
          .collection('guests')
          .doc()

        batch.set(ref, guest)
      })

      return batch
        .commit()
        .then(() => dispatch.parse.UPDATE({ imported: true }))
    },

    deleteAllGuests: async eventId => {
      console.log(`Deleting all docs for event ${eventId}`)
      // const snapshot = await db.collection(`/events/${eventId}/guests`).get()
      // snapshot.forEach(doc => {
      //   console.log(`Deleting: ${doc.id}`)
      //   doc.ref.delete()
      // })
      const deleteFn = firebase.functions().httpsCallable('deleteGuests')
      return deleteFn({ eventId }).then(() =>
        dispatch.guests.SET_GUESTS({ event: eventId, guests: [] })
      )
    }
  })
}
