import firebase from '../../firebase'
import * as Auth from '../../api/auth'

const initialState = {
  uid: '',
  profile: {
    name: '',
    email: ''
  }
}

export default {
  state: initialState, // initial state
  reducers: {
    // handle state changes with pure functions
    LOGIN(state, { token, uid, profile }) {
      return { ...state, token, uid, profile }
    },

    LOGOUT(state) {
      return initialState
    }
  },

  effects: dispatch => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async requestLogin(payload, rootState) {
      Auth.loginWithGoogle()
    },

    async requestLogout(payload, rootState) {
      firebase
        .auth()
        .signOut()
        .catch(console.error)
    },

    async syncUserProfile(payload, rootState) {
      // db.collection('users').put()
    },

    async watchAuthState(payload, rootState) {
      firebase.auth().onAuthStateChanged(user => {
        if (!user) return dispatch.auth.LOGOUT()

        // User is signed in.
        const payload = {
          profile: {
            name: user.displayName,
            email: user.email,
            emailVerified: user.emailVerified,
            photoURL: user.photoURL
          },
          uid: user.uid
        }

        dispatch.auth.LOGIN(payload)
      })
    }
  })
}
